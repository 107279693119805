<template>
  <div>
    <slot name="header" :documents="documents"></slot>
    <div v-if="$online">
      <v-list class="bg-main pt-0" flat>
        <div class="transparent mx-1" v-if="showFilters">
          <v-row class="px-4">
            <v-col cols="12" md="6">
              <v-text-field
                class="mb-5"
                clearable
                hide-details
                prepend-inner-icon="mdi-magnify"
                :label="$t('t_search')"
                v-model="documentsSearch"
                @input="searchDocuments"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div>
          <div v-for="(item, index) in documents" :key="item.header">
            <v-subheader v-if="item.header" v-text="item.header"></v-subheader>
            <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>
            <div v-else-if="item.isPaperOs">
              <v-list-item
                class="document-item rounded mx-2 pr-3 mb-1"
                :class="{ 'grey darken-4': $vuetify.theme.dark }"
                :key="item.id"
              >
                <v-list-item-content>
                  <v-list-item-subtitle v-if="item" class="text-caption">
                    <span v-if="item.recipients[0].signed" class="green--text text--lighten-1"> Completed </span>
                    <span v-else class="red--text text--darken-2"> Pending Signature </span>
                  </v-list-item-subtitle>
                  <v-list-item-title class="text-wrap">{{ item.fileName }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-btn
                      small
                      outlined
                      v-if="item.recipients[0].signed"
                      class="mt-2"
                      :href="item.recipients[0].signatureUri"
                      target="_blank"
                    >
                      <v-icon small class="mr-2">mdi-open-in-new</v-icon> View Document
                    </v-btn>
                    <v-btn
                      color="primary"
                      small
                      v-if="!item.recipients[0].signed"
                      class="mt-2"
                      :href="item.recipients[0].signatureUri"
                      target="_blank"
                    >
                      <v-icon small class="mr-2">mdi-open-in-new</v-icon> View &amp; Sign
                    </v-btn>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
            <DocumentListItem
              v-else
              ref="documentListItem"
              :item="item"
              :parent="'documents'"
              :key="'document-' + index"
              @showDocument="showDocument(item)"
            />
          </div>
        </div>
      </v-list>
      <v-btn
        v-if="showLoadMore && !loading && documents && documents.length > 0"
        class="mx-3 mb-3"
        @click="loadMoreDocuments"
        >{{ $t("t_load_more") }}</v-btn
      >
    </div>
    <h4 class="pa-4" v-if="(documents?.length == 0 && !loading) || !$online">
      {{ $t("t_no_results") }} {{ !$online ? " : " + $t("t_no_network_connection") : "" }}
    </h4>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Helpers from "@/mixins/helpers";
import DocumentListItem from "@/components/documents/DocumentListItem.vue";
import debounce from "lodash/debounce";
import { DocumentService, UserService } from "@/services";
import { App } from "@capacitor/app";

export default {
  name: "DocumentList",
  mixins: [Helpers],
  components: { DocumentListItem },
  props: {
    documentsTakeNum: {
      type: Number,
      default: 20,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    documents: [],
    documentsOrderByField: "Id",
    documentsAscending: false,
    documentsSkipNum: 0,
    documentsSearch: "",
    documentsTag: "",
    showLoadMore: false,
    appStateListenerAdded: false,
  }),
  methods: {
    ...mapActions("document", ["getDocuments"]),
    async loadDocuments(refresh = false) {
      this.loading = true;
      if (!this.$online) {
        return;
      }
      this.showLoadBar();
      let params = {
        "paging.orderbyfield": this.documentsOrderByField,
        "paging.ascending": this.documentsAscending,
        "paging.skipnum": this.documentsSkipNum,
        "paging.takenum": this.documentsTakeNum,
      };
      if (this.documentsSearch) {
        params.content = this.documentsSearch;
      }
      if (this.documentsTag) {
        params.tag = this.documentsTag;
      }
      if (refresh) {
        this.documents = [];
      }
      let legalDocuments = await this.loadDocumentsLegal();
      if (this.documentsSearch && legalDocuments) {
        legalDocuments = legalDocuments.filter((docs) =>
          docs.fileName.toLowerCase().includes(this.documentsSearch.toLowerCase()),
        );
      }
      this.getDocuments(params).then((r) => {
        let documents = r.data?.map((doc) => ({ ...doc, isRanes: true }));
        let signedDocs = legalDocuments?.filter((docs) => docs.recipients[0].signed);
        let unSignedDocs = legalDocuments?.filter((docs) => !docs.recipients[0].signed);
        if (!signedDocs) {
          signedDocs = [];
        }
        if (!unSignedDocs) {
          unSignedDocs = [];
        }
        let allDocuments = [...unSignedDocs, ...documents, ...signedDocs];
        allDocuments.forEach((m) => {
          this.documents.push(m);
        });
        if (r.meta?.endOfResults) {
          this.showLoadMore = false;
        } else {
          this.showLoadMore = true;
        }
        this.loading = false;
        this.hideLoadBar();
        //Show or hide documents if they exist or not - nav defined on app data
        if (this.documents.length == 0) {
          this.$root.nav = this.$root.nav.filter((item) => item !== "documents");
        } else {
          !this.$root.nav.includes("documents") ? this.$root.nav.push("documents") : null;
        }
      });
    },

    loadMoreDocuments() {
      this.documentsSkipNum = this.documents.length;
      this.loadDocuments();
    },

    async loadMoreSubmissions() {
      this.submissionsSkipNum = this.submissions.length;
      this.loadSubmissions();
    },

    searchDocuments: debounce(function () {
      this.loadDocuments(true);
    }, 500),

    async loadDocumentsLegal() {
      const r = await UserService.getUser();
      const id = r.data?.paperOSUserIntegrationId;
      if (!id) {
        return;
      }
      const ri = await DocumentService.getDocumentUserIntegration(id);
      const integration = ri?.data;
      const meta = JSON.parse(ri.data.metaData);
      const d = await DocumentService.getDocumentsPaperOs({ orgId: meta.org_id, email: integration.user?.email });
      let docs = d.data?.documents;
      docs = docs.map((doc) => ({ ...doc, isPaperOs: true }));
      return docs;
    },

    appStateChangeHandler(state) {
      if (state.isActive) {
        this.loadDocuments(true);
      }
    },
  },
  mounted() {
    this.loadDocuments();
    if (this.preview) {
      this.loading = true;
    }
    if (!this.appStateListenerAdded) {
      App.addListener("appStateChange", this.appStateChangeHandler);
      this.appStateListenerAdded = true;
    }
  },
  beforeDestroy() {
    if (this.appStateListenerAdded) {
      App.removeAllListeners();
      this.appStateListenerAdded = false;
    }
  },
};
</script>
